<template>
  <feed-card-layout :post="post">
    <div class="card-promocode">
      <div class="mem-show-desktop">
        <v-img :src="post.desktop_image">
          <template v-slot:placeholder>
            <div class="post-skeleton"></div>
          </template>
        </v-img>
      </div>
      <div class="mem-show-mobile">
        <v-img :src="post.mobile_image">
          <template v-slot:placeholder>
            <div class="post-skeleton"></div>
          </template>
        </v-img>
      </div>
      <div class="promocode-discount">
        <div class="discount-text" v-html="post.additional_text"></div>
      </div>
    </div>
  </feed-card-layout>
</template>
<script>
export default {
  data: () => ({}),
  props: ["post"],
  components: {
    "feed-card-layout": () => import("./FeedCardLayout.vue"),
  },
};
</script>
<style lang="scss" scoped>
.card-promocode {
  position: relative;
  height: 268px;
  @media screen and (max-width: $mobile) {
    height: 230px;
  }
  .post-skeleton {
    height: 100%;
    animation: skeleton-loading 0.5s linear infinite alternate;
  }
  .mem-show-desktop,
  .mem-show-mobile {
    height: inherit;
  }
  .v-image {
    height: 100%;
  }
  .promocode-discount {
    position: absolute;
    bottom: 28px;
    right: 0;
    // min-height: 114px;
    max-width: 282px;

    padding: 16px 25px;
    // background-color: rgba(85, 0, 0, 0.65);
    background-color: rgba(90, 0, 0, 0.65);
    // background-blend-mode: multiply;
    backdrop-filter: blur(2px);
    @media screen and (max-width: $mobile) {
      max-width: 220px;
    }

    .discount-text {
      font-family: "Druk";
      font-style: italic;
      font-weight: 700;
      font-size: 45px;
      line-height: 95%;

      letter-spacing: 0.02em;
      text-transform: uppercase;
      text-align: end;
      @media screen and (max-width: $mobile) {
        font-size: 32px;
      }
    }
  }
}
</style>