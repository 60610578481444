<template>
  <component :is="feedCardtype" :post="post.data" />
</template>
<script>
import FeedCardDefault from "./FeedCardTypeDefault.vue";
import FeedCardResult from "./FeedCardTypeResult.vue";
import FeedCardPromocode from "./FeedCardTypePromocode.vue";
export default {
  props: ["post"],
  computed: {
    feedCardtype() {
      if (this.post.template === "result") return FeedCardResult;
      if (this.post.template === "promocode") return FeedCardPromocode;
      return FeedCardDefault;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>