<template>
  <feed-card-layout :post="post">
    <div class="card-result">
      <div class="mem-show-desktop">
        <v-img :src="post.desktop_image">
          <template v-slot:placeholder>
            <div class="post-skeleton"></div>
          </template>
        </v-img>
      </div>
      <div class="mem-show-mobile">
        <v-img :src="post.mobile_image">
          <template v-slot:placeholder>
            <div class="post-skeleton"></div>
          </template>
        </v-img>
      </div>
      <div class="card-content">
        <div class="card-top"></div>
        <div class="card-bottom">
          <div class="result-time">
            <span class="time-value">{{ additional.completion_time }}</span>
            <span class="material-icons-outlined"> timer </span>
          </div>
          <div class="result-data">
            <div class="data-item">
              <span class="item-value">{{ $t("top") + " " + percentile }}</span>
              <span class="item-subline">{{ heat + " " + $t("heat") }}</span>
            </div>
            <div class="data-item">
              <div class="item-value">
                <span>{{ additional.rank_overall }}</span>
                <span class="overall-value"
                  >/{{ additional.count_overall }}</span
                >
              </div>
              <div class="item-subline">
                {{ $t("rank") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </feed-card-layout>
</template>

<script>
export default {
  data: () => ({}),
  props: ["post"],
  components: {
    "feed-card-layout": () => import("./FeedCardLayout.vue"),
  },
  computed: {
    additional() {
      return this.post.additional || {};
    },
    heat() {
      if (!this.additional.normalized_heat) return "";
      return this.additional.normalized_heat.replace("_", " ");
    },
    percentile() {
      if (!this.additional.rank_overall || !this.additional.count_overall)
        return "--";
      let result = Math.round(
        (this.additional.rank_overall / this.additional.count_overall) * 100
      );
      return `${result} %`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-result {
  position: relative;
  height: 268px;
  @media screen and (max-width: $mobile) {
    height: 230px;
  }
  .post-skeleton {
    height: 100%;
    animation: skeleton-loading 0.5s linear infinite alternate;
  }
  .mem-show-desktop,
  .mem-show-mobile {
    height: inherit;
  }
  .v-image {
    height: 100%;
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 5%,
      rgba(0, 0, 0, 0.4) 20%,
      #000000 40%
    );
    @media screen and (max-width: $mobile) {
      mask-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 5%,
        rgba(0, 0, 0, 0.4) 20%,
        #000000 40%
      );
    }
  }
  .card-content {
    position: absolute;
    top: 0;
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    .card-top {
      @media screen and (max-width: $mobile) {
        order: 2;
      }
    }
    .card-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      @media screen and (max-width: $mobile) {
        order: 1;
      }
      .result-time {
        font-family: "Druk", sans-serif;
        font-size: 54px;
        font-weight: 400;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        line-height: initial;
        @media screen and (max-width: $mobile) {
          font-size: 44px;
        }
        .time-value {
          max-height: 54px;
          margin-right: 10px;
          @media screen and (max-width: $mobile) {
            max-height: 42px;
            margin-right: 8px;
          }
        }
        .material-icons-outlined {
          color: #bbbbbb;
          @media screen and (max-width: $mobile) {
            font-size: 18px;
          }
        }
      }
      .result-data {
        display: flex;
        flex-direction: row;
        .data-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 32px;
          .item-value {
            font-family: "Druk", sans-serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 35px;
            text-transform: uppercase;
            @media screen and (max-width: $mobile) {
              font-size: 24px;
              line-height: 28px;
            }
            .overall-value {
              color: #c7c1bf;
            }
          }
          .item-subline {
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            text-transform: uppercase;
            @media screen and (max-width: $mobile) {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>