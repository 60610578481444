<template>
  <feed-card-layout :post="post">
    <div class="card-default mem-show-desktop">
      <v-img :src="post.desktop_image">
        <template v-slot:placeholder>
          <div class="post-skeleton"></div>
        </template>
      </v-img>
    </div>
    <div class="card-default mem-show-mobile">
      <v-img :src="post.mobile_image">
        <template v-slot:placeholder>
          <div class="post-skeleton"></div>
        </template>
      </v-img>
    </div>
  </feed-card-layout>
</template>
<script>
import FeedCardLayout from "./FeedCardLayout.vue";
export default {
  data: () => ({}),
  props: ["post"],
  components: {
    FeedCardLayout,
  },
};
</script>
<style lang="scss" scoped>
.card-default {
  height: 268px;
  @media screen and (max-width: $mobile) {
    height: 230px;
  }
  .post-skeleton {
    height: 100%;
    animation: skeleton-loading 0.5s linear infinite alternate;
  }
  .v-image {
    height: 100%;
  }
}
</style>