<template>
  <div class="feed-card">
    <div class="post-author">
      <div class="author-avatar">
        <template v-if="showAvatar">
          <img
            v-if="post.author && post.author.image"
            :src="post.author.image"
          />
          <template v-else>
            <default-avatar
              :size="40"
              :font-size="22"
              :text="post.author.title"
            ></default-avatar>
            <default-avatar
              class="mobile-size"
              :size="34"
              :font-size="22"
              :text="post.author.title"
            ></default-avatar>
          </template>
        </template>
        <img v-if="!showAvatar" src="@/assets/spartan_logo.svg" alt="logo" />
      </div>
      <div>
        <div class="author-name">{{ post.author.title }}</div>
        <div class="authot-subline">{{ post.category || post.created_at }}</div>
      </div>
    </div>
    <div class="post-content">
      <div class="content-header">
        <div class="post-title" v-html="post.header"></div>
        <mem-button
          @click="openLink"
          class="post-button"
          :btnType="'secondary-dark'"
          >{{ post.button.label }}</mem-button
        >
      </div>
      <div class="content-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    showAvatar() {
      let { author } = this.post;
      if (!author) return false;
      return author.hasOwnProperty("image");
    },
  },
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "default-avatar": () => import("@/components/DefaultAvatar.vue"),
  },
  methods: {
    openLink() {
      let link = this.post.button.url;
      if (!link) return;

      const accountHosts = [
        "account.spartan.com",
        "account-staging.spartan.com",
      ];
      let linkObj = new URL(link);

      if (accountHosts.includes(linkObj.host))
        return this.$router.push({ path: linkObj.pathname });

      let windowReference = window.open();
      windowReference.location = link;
    },
  },
};
</script>
<style lang="scss" scoped>
.feed-card {
  padding: 40px 49px;
  background-color: #121212;
  margin-bottom: 4px;
  @media screen and (max-width: $mobile) {
    padding: 20px 0;
  }
  .post-author {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    height: 40px;
    @media screen and (max-width: $mobile) {
      height: 34px;
      padding: 0 16px;
      margin-bottom: 20px;
    }
    .author-avatar {
      margin-right: 16px;
      @media screen and (max-width: $mobile) {
        margin-right: 12px;
      }
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        @media screen and (max-width: $mobile) {
          height: 34px;
          width: 34px;
        }
      }
      .default-avatar {
        @media screen and (max-width: $mobile) {
          display: none;
        }
      }
      .mobile-size {
        display: none;
        @media screen and (max-width: $mobile) {
          display: flex;
        }
      }
    }
    .author-name {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 6px;
      @media screen and (max-width: $mobile) {
        margin-bottom: 4px;
      }
    }
    .authot-subline {
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      letter-spacing: 0.005em;
      color: #757474;
    }
  }
  .post-content {
    max-width: 460px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .content-header {
      display: grid;
      grid-template-columns: 1fr max-content;
      // flex-direction: row;
      // justify-content: space-between;
      min-height: 50px;
      margin-bottom: 20px;
      gap: 16px;
      @media screen and (max-width: $mobile) {
        order: 2;
        min-height: 40px;
        padding: 0 16px;
        margin-bottom: 0;
        margin-top: 20px;
      }
      .post-title {
        font-size: 18px;
        line-height: 25px;
        font-weight: bold;
        @media screen and (max-width: $mobile) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .post-button {
        height: 34px;
        padding-left: 16px;
        padding-right: 16px;

        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
    .content-body {
      @media screen and (max-width: $mobile) {
        order: 1;
        max-width: 100vw;
      }
    }
  }
}
</style>